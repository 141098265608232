import { Injectable } from '@angular/core';

declare var _paq:any;
declare var uid: string;

@Injectable()
export class MatomoService {

	constructor() { }
	
	
	public setUid(newUid: string):void
	{
		uid = newUid;
	}
	
	
	public emitEvent(eventCategory: string,
		eventAction: string,
		eventLabel: string = null,
		eventValue: number = null) {
		
		//console.log("GA.emitEvent("+eventCategory+", "+eventAction+")");
		if(typeof _paq !== 'undefined')
		{
			_paq.push([
				'trackEvent',
				eventCategory,
				eventAction,
				eventLabel,
				eventValue,
				{dimension1: uid}
			]);
		}
		
	}
	
	
	public emitPage(page:string) {
		
		//console.log("GA.emitPage("+page+")");
		if(typeof _paq !== 'undefined')
		{
			_paq.push(['trackPageView']);
		}
	}
}
