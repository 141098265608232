export interface QuizItem{
	
	id:string,
	question:string,
	answer1:string,
	answer2:string,
	answer3:string,
	solution:string,
};


export function createMock(data:any = null):QuizItem{
	let output:QuizItem = {
		id:'',
		question:'',
		answer1:'',
		answer2:'',
		answer3:'',
		solution:'',
	};
	output = {...output, ...data};
	return output;
};