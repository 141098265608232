import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { ModalsService } from 'src/app/shared/navigation/modals.service';

@Component({
  selector: 'app-generique',
  templateUrl: './generique.component.html',
  styleUrls: ['./generique.component.css']
})
export class GeneriqueComponent implements AfterViewInit {

  constructor(
    private router: Router,
    private modalService:ModalsService
  ) { }

  ngOnInit(): void {
  }

  @ViewChild("introVideoPlayer", {static: true}) videoPlayer: ElementRef;

  ngAfterViewInit(){
    this.videoPlayer.nativeElement.play();
  }

  startGame(): void {
    //this.router.navigate(['/game']);
    this.modalService.open('game');
  }
}
