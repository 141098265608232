import { query, transition, trigger, group, useAnimation, animate, animation, animateChild, style, stagger, sequence, state } from '@angular/animations';
import { dsl } from './app.animations';



/*
decorator :
animations: [xAnimations],
declarations :
@HostBinding('@animations') private animations;

composition :
query('.dsl_x', group([
	dsl.zoomIn(0, {scaleStart: 2}),
])),
*/

export const quizAnimation = [
	
  trigger('animations', [
		
    transition(':enter', group([
			 /* 
			query('.dsl_fade1', dsl.reset().fadeIn(0.2)),
			query('.dsl_zoomx', dsl.zoomIn(0.2, {zoomX:true})),
			query('.dsl_fade2', dsl.fadeIn(0.15)),
			query('.dsl_fade3', dsl.fadeIn(0.15)),
			...dsl.sequenceLoop('.dsl_container > *:nth-child({{i1}})', 4, dsl.slideIn, 0.1, 0.2, false, {distanceIn:40}),
			query('.dsl_fade4', dsl.fadeIn(0.15)),
			*/
			
			query('.dsl_right1', dsl.reset().slideIn(0.3, {dir:'right', useMargin:true})),
			query('.dsl_right2', dsl.slideIn(0.2, {dir:'right', useMargin:true})),
			 
			
			// query('.dsl_fadeout', dsl.reset().fadeIn()),
			query('.dsl_up1', dsl.slideIn(0.4, {distanceIn:50})),
			query('.dsl_up2', dsl.slideIn(0.2, {distanceIn:50})),
			// ...dsl.sequence(['.dsl_fade1', '.dsl_fade2', '.dsl_fade3'], dsl.reset().fadeIn, 0.5)
			
		])),
		
		transition(':leave', group([
			
			query('.dsl_fadeout', dsl.reset().fadeOut()),
			
		])),
		
		
		
		
		
		transition('* => true', group([
			 
			query('.dsl_fade3', dsl.reset().fadeIn(0.15)),
			...dsl.sequenceLoop('.dsl_container > *:nth-child({{i1}})', 4, dsl.slideIn, 0.1, 0.2, false, {distanceIn:40}),
			query('.dsl_fade4', dsl.fadeIn(0.15)),
			
			
		])),
		
		transition('* => false', group([
			
			// query('.dsl_fadeout', dsl.reset().fadeOut()),
			
		])),
		
  ])
];



export const quizSubAnimation = [
	
  trigger('subAnimation', [
		
		
		state('false', style({ opacity:0})),
		state('true', style({ opacity:1})),
		
    transition('* => true', group([
			 
			query('.dsl_fade3', dsl.reset().fadeIn(0.15)),
			...dsl.sequenceLoop('.dsl_container > *:nth-child({{i1}})', 4, dsl.slideIn, 0.1, 0.2, false, {distanceIn:40}),
			query('.dsl_fade4', dsl.fadeIn(0.15)),
			
		])),
		
		transition('* => false', group([
			
			// query('.dsl_fadeout', dsl.reset().fadeOut()),
			dsl.reset().fadeOut(),
		])),
		
  ])
];
