import { ModalHistoryComponent } from './modals/modal-history/modal-history.component';
import { ConfirmquitComponent } from './pages/confirmquit/confirmquit.component';
import { MenuComponent } from './pages/menu/menu.component';
import { ChapterStartComponent } from './pages/chapter-start/chapter-start.component';
import { HomeComponent } from './pages/home/home.component';
import { LandingComponent } from './pages/landing/landing.component';
import { PageGameComponent } from './pages/page-game/page-game.component';
import { Routes } from '@angular/router';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { ChapterEndComponent } from './pages/chapter-end/chapter-end.component';
import { CreditsComponent } from './pages/credits/credits.component';
import { ScoreSendComponent } from './pages/score-send/score-send.component';
import { EndGameComponent } from './pages/end-game/end-game.component';
import { Gameover2Component } from './pages/gameover2/gameover2.component';
import { TutoComponent } from './pages/tuto/tuto.component';
import { LanguageSelectComponent } from './pages/language-select/language-select.component';
import { ThankComponent } from './pages/thank/thank.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { MesureImpactComponent } from './pages/mesure-impact/mesure-impact.component';
import { LegalComponent } from './pages/legal/legal.component';
import { QuizLeaderboardComponent } from './pages/quiz-leaderboard/quiz-leaderboard.component';
import { MenuLeaderboardComponent } from './pages/menu-leaderboard/menu-leaderboard.component';
import { SharePopupComponent } from './pages/quiz-leaderboard/widgets/share-popup/share-popup.component';
import { GeneriqueComponent } from './pages/generique/generique.component';



export const routes:Routes = [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
		
	{ path: 'landing', component: LandingComponent},
	{ path: 'languages', component: LanguageSelectComponent},
    { path: 'home', component: HomeComponent},
    { path: 'login', component: CreateAccountComponent},
    { path: 'game', component: PageGameComponent},
    { path: 'quiz', component: QuizComponent},
	{ path: 'tuto', component: TutoComponent},
    { path: 'chapter-start', component: ChapterStartComponent},
		{ path: 'chapter-end', component: ChapterEndComponent },
		{ path: 'gameover2', component: Gameover2Component },
		{ path: 'scoreshare', component: SharePopupComponent},
		{ path: 'scoresend', component: ScoreSendComponent},
		{ path: 'game-end', component: EndGameComponent},
		{ path: 'thanks', component: ThankComponent},
		{ path: 'leaderboard', component: LeaderboardComponent},
		{ path: 'quiz-leaderboard', component: QuizLeaderboardComponent},
    { path: 'history', outlet: 'modal_game', component: ModalHistoryComponent},
	{ path: 'mesure-impact', component: MesureImpactComponent },
		{ path: 'menu', outlet: 'modal_menu', component: MenuComponent},
		{ path: 'confirmquit_popup', outlet: 'modal_menu', component: ConfirmquitComponent},
		{ path: 'credits_popup', outlet: 'modal_menu', component: CreditsComponent},
		{ path: 'legal_popup', outlet: 'modal_menu', component: LegalComponent},
		{ path: 'leaderboard_popup', outlet: 'modal_menu', component: MenuLeaderboardComponent},
		{ path: 'tuto_popup', outlet: 'modal_menu', component: TutoComponent},
		{ path: 'generique', component: GeneriqueComponent},
];
