import { DataService } from './services/data.service';
import { ApiService } from './services/api.service';
import { NavigationStart, Router, Routes } from '@angular/router';
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { Component, Renderer2, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { RouterEventService } from './shared/navigation/router-event.service';
import { StorageService } from './shared/storage/storage.service';
import { SoundPlayerService } from './shared/sound/sound-player.service';
import { ModalsService } from './shared/navigation/modals.service';
import { fxAnimations } from './animations/fx.animations';
import { TimeoutService } from './shared/time/timeout.service';
import { UserDataService } from './services/user-data.service';
import { variable } from '@angular/compiler/src/output/output_ast';
import { MatomoService } from './shared/api/google-analytics.service';
import { BroadcasterService } from './shared/events/broadcaster.service';
import { FullscreenService } from './shared/utils/fullscreen.service';
import { PlatformService } from './shared/utils/platform.service';
import { PreloadImgService } from './shared/sound/preload-img.service';
import { WordingsService } from './services/wordings.service';
import { GameService } from './services/game.service';
import { Title } from '@angular/platform-browser';
import { TimelineMax, Power0 } from 'gsap';
import { RoughEase } from 'gsap/EasePack';
import { setDefaultValues } from './model/company.interface';
declare var dmi: any;
declare var tarteaucitron: any;
declare var pipwerks:any;
declare function millisecondsToSCORMTime(milliseconds: number): string;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {

	@ViewChild('bg1') bg1: ElementRef;
	@ViewChild('bg2') bg2: ElementRef;
	@ViewChild('bgOverlay') bgOverlay: ElementRef;
	@ViewChild('loading') loading: ElementRef;
	@ViewChild('loadingIcon') loadingIcon: ElementRef;
	private loadingAnimIndex: number = 0;

	routes: Routes = routes;
	env = environment;
	loaded: boolean;
	showLoading: boolean = true;

	showMenu: boolean;
	bgVisible: number;

	errorCode = -1;
	hideLoading = false;

	private image: string;
	private startTime: Date;


	constructor(
		private api: ApiService,
		private ds: DataService,
		private wordings: WordingsService,
		private routerEvent: RouterEventService,
		private router: Router,
		private storage: StorageService,
		private soundPlayer: SoundPlayerService,
		private modalService: ModalsService,
		private timeout: TimeoutService,
		private userData: UserDataService,
		private trackerAnalytics: MatomoService,
		private renderer: Renderer2,
		private broadcaster: BroadcasterService,
		private fs: FullscreenService,
		private platform: PlatformService,
		private preloadImg: PreloadImgService,
		private gameService: GameService,
		private titleService: Title,
		private ngZone: NgZone
	) {

		pipwerks.SCORM.init();
		const minScore = "0";
		const maxScore = "100"; // percentages


		// SCORM 2004 : cmi.core.lesson_status => cmi.completion_status
		if(pipwerks.SCORM.get('cmi.core.lesson_status') != "completed")
		{
			pipwerks.SCORM.set('cmi.core.lesson_status', "incomplete");
		}

		pipwerks.SCORM.set('cmi.core.score.max', maxScore);
		pipwerks.SCORM.set('cmi.core.score.min', minScore);
		pipwerks.SCORM.save();

		ds.modeClient = (!location.href.match(/\?test/));
		ds.modeClient = !ds.modeClient;
		this.startTime = new Date();

		var loaded = [];
		var loadedCount = 0;

		/*for(let i=0; i<25; i++)
		{
			loaded.push(false);
			var tester=new Image();
			var img = this.image;
			const imgSrc = 'assets/images/pages/loading/loading_' + (i+1) + '.png';
			const instance = this;
			tester.onload=function() {
				loaded[i] = true;
				loadedCount++;
				if(loadedCount == 25)
				{
					console.log("LOADED");
					instance.loadGameData();
				}
			}
			tester.src=imgSrc;
		};*/
		let timeline = new TimelineMax({ repeat: -1 });
		for (let i = 1; i < 25; i++) {
			timeline.call(() => this.ngZone.run(() => {
				this.loadingAnimIndex = i;
			}), null, "+=0.1");
		}
		timeline.call(() => {

			if (this.loaded)
				this.ngZone.run(() => { this.showLoading = false });
		}
		);
		this.loadGameData();


		this.timeout.enabled = !this.env.skipTimeout;
		this.userData.init();

		let tab = location.href.match(new RegExp("uid=(.+)$"));
		this.ds.uid = tab && tab.length >= 1 && tab[1];

		this.ds.modeConnexion = (this.ds.uid == null || this.ds.uid == environment.defaultCompany) && environment.promptConnexion;

		let uid = tab && tab.length >= 1 && tab[1];
		if (uid == null)
			uid = environment.defaultCompany;
		if (!document.cookie.includes("registered=")) {
			this.api.registerUser(uid).subscribe(resp => {

			});
			document.cookie = "registered=true; expires=9999-12-31T12:00:00.000Z";
		}
		this.trackerAnalytics.setUid(uid);

		this.modalService.addOutletListener(null, () => {
			this.trackerAnalytics.emitEvent('alive', 'alive');
		});

		//init storage
		if (!this.storage.isset('sound')) this.storage.setBoolean('sound', true);

		//let uid: string = this.ds.uid || this.api.defaultUID;
		//this.trackerAnalytics.setUid(uid);

		routerEvent.setRoutes(routes);

		routerEvent.addListeners(
			[{
				routes: ['gameover2'],
				handlerEnter: (route) => {

					this.soundPlayer.pause('assets/sounds/gametheme.mp3');
					this.soundPlayer.pauseGroup('background');
					this.soundPlayer.playLooping('assets/sounds/menutheme.mp3', {
						volume: 0.2,
						loop: true,
						forceStart: false,
						group: 'music'
					});
					/*this.soundPlayer.play('assets/sounds/gameover_bad.mp3', {
						volume: 0.2,
						forceStart: false,
						group: 'music',
					});*/
				}
			},
			{
				routes: ['chapter-end'],
				handlerEnter: (route) => {
					this.soundPlayer.pause('assets/sounds/gametheme.mp3');
					this.soundPlayer.pauseGroup('background');
					this.soundPlayer.playLooping('assets/sounds/menutheme.mp3', {
						volume: 0.2,
						loop: true,
						forceStart: false,
						group: 'music'
					});
					/*this.soundPlayer.play('assets/sounds/gameover_good.mp3', {
						volume: 0.2,
						forceStart: false,
						group: 'music',
					});*/
				}
			},
			{
				routes: ['game'],
				handlerEnter: (route) => {
					this.soundPlayer.pause('assets/sounds/menutheme.mp3');
					/*this.soundPlayer.playLooping('assets/sounds/gametheme.mp3', {
						volume: 0.2,
						loop: true,
						forceStart: false,
						group: 'music',
					});*/
				},
			},
			{
				routes: ['chapter-start'],
				handlerEnter: (route) => {
					let tutoVisible: boolean = this.routerEvent.isRouteActive('tuto');
					if (!tutoVisible) {
						this.soundPlayer.pause('assets/sounds/gametheme.mp3');
						this.soundPlayer.pauseGroup('background');
						this.soundPlayer.playLooping('assets/sounds/menutheme.mp3', {
							volume: 0.2,
							loop: true,
							forceStart: false,
							group: 'music'
						});
					}

				},
			}],
			() => {
				this.soundPlayer.pause('assets/sounds/gametheme.mp3');
				this.soundPlayer.pauseGroup('background');
				this.soundPlayer.pause('assets/sounds/menutheme.mp3');
			}, true
		);


		/*routerEvent.addListener(
			['create-account', 'credits', 'leaderboard'], 
			(route) => {
				if (route == 'leaderboard') {
					this.showBack = !this.routerEvent.isRouteActive('scoresend');
				}
				else this.showBack = true;

			},
			() => this.showBack = false,
			true,
		);*/

		router.events.subscribe(
			val => {
				if (val instanceof NavigationStart) {
					console.info((val as NavigationStart).url);
				}
			}
		);
		routerEvent.addListener(
			['game', 'quiz', 'scoresend', 'leaderboard', 'tuto'],
			(route: string) => {
				this.showMenu = this.userData.data.indexScenario > 0 && this.userData.data.indexScenario < (this.ds.scenarios ? this.ds.scenarios.length - 1 : 0);
			},
			() => {

				this.showMenu = false;
			},
			true,
		);


		routerEvent.addListener(
			['game', 'tuto'],
			(route: string) => {
				this.showMenu = (this.userData.data.indexScenario > 0 && this.userData.data.indexScenario < (this.ds.scenarios ? this.ds.scenarios.length - 1 : 0) || this.ds.uid == 'test-version-com');
			},
			() => {

				this.showMenu = false;
			},
			true,
		);

		routerEvent.addListener(
			['menu'],
			(route: string) => {
			},
			() => { },
			true,
		);
	}

	getLoadingStyle(index) {
		if (index == this.loadingAnimIndex)
			return { "opacity": 1 };
		else
			return { "opacity": 0 };
	}

	loadGameData() {
		console.log("LOAD GAME DATA");
		let tab = location.href.match(new RegExp("uid=(.+)$"));
		let uid = tab && tab.length >= 1 && tab[1];
		if (uid == null)
			uid = environment.defaultCompany;
		if (!document.cookie.includes("registered=")) {
			this.api.registerUser(uid).subscribe(resp => {

			});
			document.cookie = "registered=true; expires=9999-12-31T12:00:00.000Z";
		}
		this.api.getData(uid).toPromise()
			.then(resp => {
				if(resp.success == false){
					this.hideLoading = true;
					throw new Error(resp.data.errorMessage);
				}else{
					this.ds.company = resp.data.company;
					//console.log(this.ds.company);
					this.ds.quiz = resp.data.quiz;
					//console.log(this.ds.quiz);
					this.ds.hasLogin = resp.data.hasLogin;
					this.ds.params = resp.data.params;
					this.wordings.loadWordings(resp.data.wordings, resp.data.company.availableLanguages.split(','));
					this.ds.versionAllowedForm = this.ds.uid == this.api.defaultUID || resp.data.company.impactMeasurement === "1";
					this.titleService.setTitle(this.wordings.translate("ui.game.title"));
					setDefaultValues(this.ds.company, this.wordings);
					this.ds.scenarios = resp.data.scenarios;
					this.showMenu = this.userData.data.indexScenario > 0 && this.userData.data.indexScenario < (this.ds.scenarios ? this.ds.scenarios.length - 1 : 0);
	
					// initiate user_id 
						// initiate user_id 
					// initiate user_id 
					if (this.ds.versionAllowedForm) {
						let uidPost = { uid: this.ds.uid };
						dmi.initiateUserUid(null, this.ds.projectId, this.ds.dmiFormNum, this.ds.dmiEndpointUrl, JSON.stringify(uidPost));
	
						// this.userData.addQuizScore(dmi.userIsFinished() ? this.ds.dmiBonusPoints : 0);
					}
					return this.api.getParams().toPromise();
				}
			})

			.then(resp => {

				this.ds.params = resp;

				this.loadImg();
			})
			.catch((err) => {
				this.ds.uiMessage = err.message;
			});

		//music
		let musicEnabled: boolean = this.storage.getBoolean('sound', true);

		if (musicEnabled) this.soundPlayer.enable();
		else this.soundPlayer.disable();

		this.soundPlayer.addSelectorEvent('snd_answer', 'mousedown', 'assets/sounds/btn_answerv2.mp3');
		this.soundPlayer.addSelectorEvent('snd_click', 'mousedown', 'assets/sounds/click.mp3');

		this.broadcaster.addSelectorEvent('enable_fs', 'mouseup', () => {
			// if(this.platform.isAndroid()) this.fs.openFullscreen();
			if (!environment.debug) this.fs.openFullscreen();
		});


		document.addEventListener('visibilitychange', () => {
			if (document.hidden) {
				//console.log('focus out');
				if (this.storage.getBoolean('sound')) this.soundPlayer.disable();
			}
			else {
				//console.log('focus in');
				if (this.storage.getBoolean('sound')) this.soundPlayer.enable();
			}
		}, false);
	}

	onUnload(): void {
		let sessionDuration = new Date().valueOf() - this.startTime.valueOf();
		let seconds = Math.floor(sessionDuration / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);
		let formattedDuration = Math.floor(hours/10).toString() + (hours%10).toString() + ":"
			+ Math.floor(minutes/10).toString() + (minutes%10).toString() + ":"
			+ Math.floor(seconds/10).toString() + (seconds%10).toString();
		// SCORM 2004 => cmi.core.session_time => cmi.session_time
		pipwerks.SCORM.set('cmi.core.session_time', formattedDuration);
		pipwerks.SCORM.quit();
	}

	loadImg(): void {
		let prefix: string = (!environment.production) ? 'http://poc-visual-novel.dowino.com/' : '/';

		//preloading
		this.api.getPreloadList(this.ds.uid).toPromise()
			.then(resp => {
				let toLoad = new Array<string>();
				for (let asset of resp.data.assets) {
					toLoad.push(asset.replace('./app/', document.baseURI));
				}
				this.preloadImg.preload(
					toLoad, () => {
						this.loaded = true;
						this.showLoading = false;

					});
			})
			.catch(err => {
				this.loaded = true;
			});


	}

	ngAfterViewInit(): void {
		this.gameService.initElements(this.bg1, this.bg2, this.bgOverlay);

		let timeline = new TimelineMax();
		timeline.to(this.loadingIcon.nativeElement,
			{
				opacity: 0.5,
				ease: RoughEase.config({ template: Power0.easeNone, strength: 2, points: 10, taper: "none", randomize: true, clamp: true }),
				duration: 1
			}
		).to(this.loadingIcon.nativeElement,
			{
				opacity: 1,
				ease: RoughEase.config({ template: Power0.easeNone, strength: 2, points: 10, taper: "none", randomize: true, clamp: true }),
				duration: 1
			}
		);
		timeline.repeat(-1);
		timeline.play();
	}

	clickMenu(value: boolean): void {
		this.titleService.setTitle(this.wordings.translate("ui.game.title"));
		if (value) {
			this.modalService.open('menu', 'modal_menu');

			setTimeout(() => {
				if (this.routerEvent.isOutletActive('modal_game')) {
					this.modalService.close('modal_game');
				}
			}, 50);

		}
		else this.modalService.close('modal_menu');
	}

	clickBack(): void {
		// TODO: Code something better
		if (this.routerEvent.isRouteActive('quiz-leaderboard') || this.routerEvent.isRouteActive('leaderboard') || this.routerEvent.isRouteActive('menu-leaderboard'))
			this.clickMenu(false);
		else
			this.clickMenu(true);
	}
}
