import { Component, OnInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { leaderboardAnimation } from 'src/app/animations/leaderboard.animations';
import { DataService } from 'src/app/services/data.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { UserDataService} from 'src/app/services/user-data.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
	styleUrls: ['./leaderboard.component.scss'],
	animations: [leaderboardAnimation],
})
export class LeaderboardComponent implements OnInit {
	private score: number;
	
  constructor(
	  	private wordings: WordingsService,
		private modalService:ModalsService,
		private routerEvent:RouterEventService,
		public ds:DataService,
		public userData: UserDataService,
	) { }

  	ngOnInit() {
		// #TODO : prendre le playerscoreID et le transmettre au getleaderboardaroundscore
		this.score = this.userData.getCurrentTotalScore();
  	}
  
	valid():void
	{
		this.modalService.isBound('modal_menu') ? this.modalService.open('menu', 'modal_menu') : this.modalService.open('scoreshare');
	}
	
}
