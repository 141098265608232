import { Component, OnInit, HostBinding, ViewChild, ElementRef, ApplicationRef, Output, EventEmitter, NgZone, AfterViewInit } from '@angular/core';
import { creditsAnimation } from 'src/app/animations/credits.animations';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { InfoScrollService } from 'src/app/shared/ui/info-scroll.service';
import * as $ from 'jquery';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { MatomoService } from 'src/app/shared/api/google-analytics.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { TimelineMax } from 'gsap';

declare var tarteaucitron: any;


@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
	styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit, AfterViewInit {
	
	@ViewChild('descElmt') descElmt:ElementRef;
	@ViewChild('mainContainer') mainContainer:ElementRef;
	@Output() onClose: EventEmitter<void> = new EventEmitter<void>();
	
	title:string;
	desc:string;
	
	infoScroll:InfoScrollService;

  constructor(
		
		private ds:DataService,
		private wordings: WordingsService,
		private app:ApplicationRef,
		private storage:StorageService,
		private trackerAnalytics:MatomoService,
		private ngZone: NgZone
	) { }

  ngOnInit() {
	this.title = this.wordings.translate('mentions.title');
	this.desc = this.wordings.translate('mentions.text');
  }

  ngAfterViewInit() {
	new TimelineMax().fromTo(this.mainContainer.nativeElement, {opacity: 0}, {opacity: 1, duration: 0.5});
		/* 
		if(environment.debug){
			this.desc += this.desc; this.desc += this.desc; this.desc += this.desc;
			this.desc += this.desc; this.desc += this.desc; this.desc += this.desc;
			this.desc += this.desc;
		}
		 */
		
		
	}
	
	public scroll(delta:number, distance:number):void
	{
		let elmt = this.descElmt.nativeElement;
		$(elmt).animate({
			scrollTop: elmt.scrollTop + delta * distance,
		}, 230);
	}
	
	
	private openTrackingPage():void
	{
		tarteaucitron.userInterface.openPanel();
		//console.log(this.trackerAnalytics.enabled);
	}

	private closeMenu()
	{
		new TimelineMax().to(this.mainContainer.nativeElement, 0.5, {opacity: 0})
		.call(() => this.ngZone.run(() => this.onClose.emit()));
	}
	
}
