import { Component, OnInit, ViewChild, ElementRef, ApplicationRef, NgZone, Output, EventEmitter } from '@angular/core';
import { fxAnimations } from 'src/app/animations/fx.animations';
import { AnimData, GameAnimationsService } from 'src/app/services/game-animations.service';
import {TimelineMax, gsap }from 'gsap'
import { WordingsService } from 'src/app/services/wordings.service';
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

@Component({
  selector: 'app-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss'],
	animations: [fxAnimations]
})
export class BubbleComponent implements OnInit {

  public text: string;
  public parsedText: any[];
  public version: number = 0;
  private currentVersion: number = 0;
  private targetVersion: number = 0;
  private currentVisibility: boolean = false;
  private targetVisibility: boolean = false;
  private talkerName: string;
  private talkerStance: string;
  private pendingParsedText: any[];
  private hasScroll: boolean = false;
  private bgClass: string;
  private scrollValue = 0;

  private currentAppearTL: TimelineMax;


  @ViewChild('mainElement') mainElement:ElementRef;
  @ViewChild('bubbleText') bubbleText:ElementRef;
  @ViewChild('talkerContent') talkerContent:any;
  @Output() onLinkClicked : EventEmitter<void> = new EventEmitter<void>();

  constructor(private animations: GameAnimationsService, private ngZone: NgZone, private wordings: WordingsService) { }

  getPendingAnims(): AnimData[] {
    return [];
  }

  ngOnInit() {
  }

  showText(talkerName: string, text: string, parameters: string[], voiceFile: string)
  {
    //console.log("Show Text " + text);
    //console.log(parameters);
    let version = 0;
    if(parameters.includes('Bulle2'))
      version = 1;
    if(parameters.includes('Bulle3'))
      version = 2;
    if(parameters.includes('Bulle4'))
      version = 3;
    if(parameters.includes('Bulle5'))
      version = 4;
    if(parameters.includes('Bulle6'))
      version = 5;
    if(this.targetVisibility && this.targetVersion != version)
    {
      this.animations.pushAnimation('bubble disappear', this.getDisappearAnimation(), 0, 1);
      this.targetVersion = version;
    }
    if(text != "")
    {
      this.animations.pushAnimation('bubble appear', this.getAppearAnimation(talkerName, voiceFile, version, text), 2, 12);
      this.targetVisibility = true;
      this.pendingParsedText = this.wordings.parseInfoLinks(text);
    }
    this.scrollValue = 0;

    /*let textArray = text.split(" ");
    console.log(textArray);
    this.bubbleText.nativeElement.innerHTML = "";
    for (let index = 0; index < textArray.length; index++) {
      setTimeout(() => {
        this.bubbleText.nativeElement.innerHTML += textArray[index]+" ";
      }, index*150);
    }*/
  }

  hideText()
  {
    if(this.targetVisibility)
    {
      this.animations.pushAnimation('bubble disappear', this.getDisappearAnimation(), 0, 1);
      this.targetVisibility = false;
    }
  }

  getAppearAnimation(talkerName: string, text: string, version: number, bubbletext: string): any
  {
    // stop and forward "appear timeline" if one exists and is playing
    // for when the splitted text animation is running and avoid double animation when player click fast
    if(this.currentAppearTL != undefined && this.currentAppearTL.isActive) {
      this.currentAppearTL.pause();
      this.currentAppearTL.seek(this.currentAppearTL.duration(), false);
    }

    let timeline = new TimelineMax();
    timeline.add(() => {
      this.ngZone.run(() => {
        this.hasScroll = false;
        const splitName = talkerName.split('_');
        this.talkerName = splitName[0];
        this.talkerStance = splitName[1];
        this.text = text;
        this.parsedText = [text];
        //console.log("PARSED TEXT : ");
        //console.log(this.parsedText);
        this.currentVersion = version;
        this.bgClass = "bg" + version;
        this.talkerContent.hostRef.nativeElement.scrollTop = 0;
        
        this.bubbleText.nativeElement.innerHTML = "";
      });
    });
    timeline.to(this.mainElement.nativeElement, 
    {
      opacity: 1, 
      duration: 0.2,
    });
    timeline.add(() => {
      this.ngZone.run(() => {
        this.hasScroll = this.talkerContent.hostRef.nativeElement.scrollHeight > this.talkerContent.hostRef.nativeElement.clientHeight;
      });
    })
    
    timeline.to(this.bubbleText.nativeElement, {duration:0.05*(bubbletext.split(' ').length), text:{value: bubbletext, delimiter: " "}});
    //console.log(timeline);

    // memorize current appear timeline to be able to stop it later
    this.currentAppearTL = timeline;

    return timeline;
  }

  getDisappearAnimation(): any
  {
    let timeline = new TimelineMax();
    
    timeline.to(this.mainElement.nativeElement, { opacity: 0, duration: 0.2 });
    timeline.call(() => this.ngZone.run(() => {
      this.currentVisibility = false;
    }));
    return timeline;
  }

  mustShowTalker()
  {
    return this.version == 0;
  }

  getCharacterName()
  {
    if(this.talkerName)
      return this.wordings.translate('ui.name.' + this.talkerName);
    return '';
  }

  getCharacterColorStyle()
  {
    if(this.talkerName)
      return {color: this.wordings.translate('ui.color.' + this.talkerName)};
      return {color: 'black'};
  }

  isLinkVisible() : boolean {
    //console.log("Link visible ? " + (this.pendingParsedText != null && this.pendingParsedText.length > 1));
    //console.log(this.pendingParsedText);
    return this.pendingParsedText != null && this.pendingParsedText.length > 1;
  }

  scrollDown() {
    this.scrollValue += 50;
    const availableHeight = this.talkerContent.hostRef.nativeElement.scrollHeight - this.talkerContent.hostRef.nativeElement.clientHeight;
    if(this.scrollValue > availableHeight)
      this.scrollValue = availableHeight;
    
      new TimelineMax().to(this.talkerContent.hostRef.nativeElement, {scrollTop: this.scrollValue, duration: 0.3});
  }

  scrollUp() {
    this.scrollValue -= 50;
    if(this.scrollValue < 0)
      this.scrollValue = 0;
      new TimelineMax().to(this.talkerContent.hostRef.nativeElement, {scrollTop: this.scrollValue, duration: 0.3});
  }
}
