import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, NgZone, Input, AfterViewInit } from '@angular/core';
import { AnswersComponent } from '../answers/answers.component';
import { TextLineData } from 'src/app/model/callbackinfo.interface';
import { GameAnimationsService } from 'src/app/services/game-animations.service';
import { TimelineMax } from 'gsap';
import { WordingsService } from 'src/app/services/wordings.service';
import { UserData } from 'src/app/model/userdata.interface';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-didascalie',
  templateUrl: './didascalie.component.html',
  styleUrls: ['./didascalie.component.scss']
})
export class DidascalieComponent implements OnInit, AfterViewInit {
  private isVisible: boolean;
  private displayText: string;

  private targetIsVisible: boolean;
  private targetText: any;
  private targetVisibility: boolean;
  private currentVisibility: boolean = false;
  private showAnswers: boolean = false;
  private blockInput: boolean = true;
  private timer: boolean = false;
  private timerTime: number;
  private ptrEvents: string;
  @ViewChild('mainElement') mainElement:ElementRef;
  @ViewChild('characterIcon') characterIcon: ElementRef;
  @Output() nextClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() timerPaused: boolean = false;


  constructor(private animations: GameAnimationsService, private ngZone: NgZone, private wordings: WordingsService, private userData: UserDataService) { }

  ngAfterViewInit() {
    const scenarioIndex = this.userData.data.indexScenario;
    const characterName = this.wordings.translate("ui.chap" + scenarioIndex + ".character");
    this.characterIcon.nativeElement.style['background-image'] = 'url(assets/dynamic/persos/icon_' + characterName + '.png)';
  }

  ngOnInit() {
		setInterval(() => {
			if(!this.timerPaused && this.timerTime > 0) {
				this.timerTime--;
			}
		},1000)
  }

  buttonClicked(): void
  {
    this.nextClicked.emit();
    this.blockInput = true;
    this.ngZone.run(() => {
      this.mainElement.nativeElement.style.pointerEvents = 'none';
    });
  }

  updateDisplay(talkers: string[], textLines: TextLineData[], voiceFile: string)
  {
    if(this.currentVisibility)
    {
      let timeline = new TimelineMax();
      timeline.set(this.mainElement.nativeElement, {'pointer-events': 'none'});
      timeline.to(this.mainElement.nativeElement, {'opacity': 0, duration: 0.2});
      this.animations.pushAnimation("disappear didascalie", timeline, 0, 0);
      this.currentVisibility = false;
    }
    let targetVisibility = false;
    this.targetIsVisible = false;
    let hasTimer = false;
    for(let textLine of textLines)
    {
      if(textLine.parameters.includes('Didascalie'))
      {
        this.targetIsVisible = true;
        this.targetText = voiceFile;
        targetVisibility = true;
      }
      if(textLine.parameters.includes("Timer"))
        hasTimer = true;
    }
    if(targetVisibility)
    {
      let timeline = new TimelineMax();
      timeline.call(() => {
        this.ngZone.run(() => {
          this.blockInput = true;
          this.currentVisibility = targetVisibility;
          this.timer = hasTimer;
          this.mainElement.nativeElement.style.pointerEvents = 'all';
        });
      });
      timeline.call(() => {
        this.ngZone.run(() => {
          this.isVisible = this.targetIsVisible;
          this.displayText = this.targetText;
          if(this.timer)
            this.timerTime = 120;
        });
      });
      timeline.to(this.mainElement.nativeElement, {'opacity': 1, duration: 0.2});
      this.animations.pushAnimation("appear didascalie", timeline, 2, 20);
    }
    this.targetVisibility = targetVisibility;
  }
}
