import { UserDataService } from 'src/app/services/user-data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

	constructor(
		private modalService:ModalsService,
		private userData:UserDataService,
		private ds:DataService,
		private api:ApiService,
	) { }
	
	//restart_game / restart_chapter
	
	restart(mode:string):void
	{
		//todo : if intro / outro, different...
		//mais pas sur qu'on laisse le btn menu accessible
		
		let indexScenario:number = this.userData.data.indexScenario;
	
		if(mode == 'restart_game') {
			if(location.href.match(/\?test/)) {
				location.reload();
			} else {

				this.userData.resetAll();
			}
		}
		else if(mode == 'restart_chapter') this.userData.resetChapter();
		this.userData.save();
		
		if(mode == 'restart_game'){
			this.modalService.open('home');
			//console.log('open home');
		}
		else if(mode == 'restart_chapter') this.modalService.open('chapter-start');
	}
	
	startGame():void
	{
		if(this.userData.data.indexScenario == 0)
			this.modalService.open('game');
		else if(this.userData.data.indexScenario >= this.ds.scenarios.length)
		{
			this.modalService.open('quiz');
		}
		else if(this.userData.data.indexScenario + 1 >= this.ds.scenarios.length)
		{
			this.modalService.open('game');
		}
		else this.modalService.open('chapter-start');
	}

	gameOver(): void
	{
		this.modalService.open('gameover2');
	}

	showTuto(): void
	{
		this.modalService.open('tuto');
	}
	
	afterTuto():void
	{
		this.modalService.close('modal');
	}
}
