import { environment } from '../../../environments/environment';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { Component, OnInit, HostBinding, ViewChild, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { quizAnimation, quizSubAnimation } from 'src/app/animations/quiz.animations';
import { createMock, QuizItem } from 'src/app/model/quizitem.interface';
import { DataService, QuizLocalizedItem } from 'src/app/services/data.service';
import { TimelineMax, Elastic } from "gsap";
import { UserDataService } from 'src/app/services/user-data.service';
import { MathService } from 'src/app/shared/math/math.service';
import { TweenAnimateService } from 'src/app/shared/animation/tween.animate.service';
import { SoundPlayerService } from 'src/app/shared/sound/sound-player.service';
import { Easing } from 'src/app/shared/animation/animation.interface';
import { MatomoService } from 'src/app/shared/api/google-analytics.service';
import { BroadcasterService } from 'src/app/shared/events/broadcaster.service';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { GameService } from 'src/app/services/game.service';
declare var dmi: any;

@Component({
	selector: 'app-quiz',
	templateUrl: './quiz.component.html',
	styleUrls: ['./quiz.component.scss'],
	animations: [quizAnimation, quizSubAnimation],
})
export class QuizComponent implements OnInit {

	@ViewChild('answers') answersElmt: ElementRef;
	@ViewChild('flash') flashElement: ElementRef;
	@ViewChild('bonus') bonusElement: ElementRef;
	@ViewChild('bonusText') bonusTextElement: ElementRef;
	@ViewChild('timerFilling') timerFilling: ElementRef;

	private scoreType: number;

	// @HostBinding('@animations') private animations;
	constructor(
		private wordings: WordingsService,
		private ds: DataService,
		private modalService: ModalsService,
		private userData: UserDataService,
		private math: MathService,
		private soundPlayer: SoundPlayerService,
		private trackerAnalytics: MatomoService,
		private routerEvent: RouterEventService,
		private ngZone: NgZone,
		private changeDetector: ChangeDetectorRef,
		private gameService: GameService,
	) { }

	public item: QuizItem;
	public currentQuestions: any;
	index: number;
	selectedAnswer: number;
	score: number;
	scoreDisplay: number;
	pts_won: number;
	submitted: boolean;
	showFlash: boolean;
	styleTimerFilling: any;
	styleTimerTip: any;
	timerProgress: number;
	interval;
	displayTuto: boolean;
	nbquestions: number;
	questions: QuizLocalizedItem[];
	flashColor: string;
	visibleAnswers: number[] = [];
	question: string;
	quizIndex: number;


	ngOnInit() {
		// on reset le score avant de commencer le quiz pour éviter les problèmes si on rafraîchit la page
		this.userData.data.quizScores[this.userData.data.indexScenario-1] = 0;
		this.userData.data.quizScores[3] = 0;
		
		this.gameService.hideBackgroundWithFade();
		this.index = (environment.debug) ? 0 : 0;
		this.score = this.userData.getCurrentScoreWithoutMI();
		this.scoreDisplay = this.userData.getCurrentScoreWithoutMI();
		this.showFlash = false;

		this.displayTuto = true;
		// this.animations = false;
		this.quizIndex = this.userData.data.indexScenario < this.ds.scenarios.length ? this.userData.data.indexScenario : 0;
		this.questions = this.ds.quiz ? this.ds.quiz[this.quizIndex > 0 ? this.quizIndex : ""] : [];
		this.nbquestions = this.questions ? this.questions.length : 0;

		this.routerEvent.addListener(['quiz'],
			() => { },
			() => {
				clearInterval(this.interval);
				this.soundPlayer.pause('assets/sounds/cobraz_quiz_time.mp3');
			});
		this.scoreType = this.userData.data.indexScenario;
		if(this.scoreType >= this.ds.scenarios.length)
		{
			this.scoreType = 0;
		}
	}


	clickValidTuto(): void {
		this.displayTuto = false;

		this.update();
	}


	setTimerProgress(progress: number): void {
		let width: number = 536 * progress;
		this.ngZone.run(() => {
			if (this.timerFilling != null)
				this.timerFilling.nativeElement.style.left = (1 - progress) * 100 + '%';
		});
	}


	clickAnswer(index: number): void {
		this.selectedAnswer = index;
	}

	valid(): void {
		// ---------DEBUG---------
		// this.index = this.ds.quiz.length;
		// ---------/DEBUG---------
		if (this.selectedAnswer != -1) {

			this.trackerAnalytics.emitEvent('quiz', 'reponse', 'q' + (this.index + 1) + '_a' + (this.selectedAnswer));

			this.submitted = true;
			let success: boolean = (this.selectedAnswer + '') == this.item.solution;
			// if(environment.debug) success = true;

			let timeline = new TimelineMax();

			if (success) {
				let p: any = this.ds.params.score.quiz;
				let time: number = (1 - this.timerProgress) * p.time;
				time = Math.floor(time);
				let nbpts: number = this.math.getProgressionValue(time, p.time_min, p.time_max, p.pts_max, p.pts_min);
				nbpts = Math.round(nbpts);

				//this.userData.data.currentScore += nbpts;
				this.pts_won = nbpts;

				timeline.fromTo(this.flashElement.nativeElement, { 'background-color': "#7cce33", opacity: 0 }, { 'background-color': '#7cce33', opacity: 1, duration: 0.1 });
				timeline.to(this.flashElement.nativeElement, { 'background-color': '#7cce33', opacity: 0, duration: 0.3 });
				timeline.to(
					this,
					{
						score: this.score + nbpts,
						duration: 0.6,
						onUpdate: () => {
							this.ngZone.run(() => { this.scoreDisplay = Math.round(this.score); });
						}
					},
					0
				);
				timeline.fromTo(this.bonusElement.nativeElement,
					{
						transform: 'translate(0, 30px)',
					},
					{
						transform: 'translate(0, 0px)',
						duration: 2,
						onUpdate: () => { this.changeDetector.detectChanges() }
					},
					0
				);
				timeline.fromTo(this.bonusTextElement.nativeElement,
					{
						transform: 'scale(0)',
					},
					{
						transform: 'scale(1)',
						duration: 0.3,
					},
					0
				);
				timeline.fromTo(this.bonusElement.nativeElement,
					{
						opacity: 0,
					},
					{
						opacity: 1,
						duration: 1,
					},
					0
				);
				timeline.to(this.bonusElement.nativeElement,
					{
						opacity: 0,
						duration: 1,
					},
					1
				);

				/*this.animate.zoomIn('.pts_won_value', 0, {
					timeIn: 0.15,
					easeIn: Easing.NONE,
					fade: false,
				});
				
				this.animate.animBonus('.pts_won_value', 0.07, {
					time:0.7,
					useMargin: true,
					startValue: 55,
					endValue: 10,
				});*/

			}
			else{
				timeline.fromTo(this.flashElement.nativeElement, {'background-color': "#c21c37", opacity: 0}, {'background-color': "#c21c37", opacity: 1, duration: 0.1});
				timeline.to(this.flashElement.nativeElement, {'background-color': "#c21c37", opacity: 0, duration: 0.3});
				this.soundPlayer.play('assets/sounds/cobraz_quiz_fail.mp3', { volume: 0.3 });
			}

			this.soundPlayer.pause('assets/sounds/cobraz_quiz_time.mp3');
			clearInterval(this.interval);

			if (!environment.debug || true) {
				setTimeout(() => {

					this.index++;
					this.update();
				}, 1000);
			}
		}
	}

	update(): void {
		//end
		if (this.index >= this.nbquestions) {

			clearInterval(this.interval);
			this.userData.addQuizScore(this.score - this.userData.getCurrentScoreWithoutMI());
			let indexScenario:number = this.userData.data.indexScenario;
			if(indexScenario >= this.ds.scenarios.length)
			{
				this.modalService.open('scoresend');
			}
			else
			{
				this.modalService.open('quiz-leaderboard');
			}
			return;
		}

		this.submitted = false;
		this.selectedAnswer = -1;
		this.currentQuestions = this.questions != null && this.questions.length > this.index ? this.questions[this.index] : createMock();
		this.item = this.currentQuestions[this.wordings.selectedLanguage];
		this.visibleAnswers = [];
		if (this.item.answer1.length > 0)
			this.visibleAnswers.push(1);
		if (this.item.answer2.length > 0)
			this.visibleAnswers.push(2);
		if (this.item.answer3.length > 0)
			this.visibleAnswers.push(3);

		this.trackerAnalytics.emitEvent('quiz', 'question', 'q' + (this.index + 1));

		this.timerProgress = 1.0;
		this.setTimerProgress(this.timerProgress);


		let timeout: number = (this.index == 0) ? 2000 : 800;
		clearInterval(this.interval);
		let decrement: number = (1 / 30) / this.ds.params.score.quiz.time;

		setTimeout(() => {

			this.soundPlayer.play('assets/sounds/cobraz_quiz_time.mp3', {
				loop: true,
				volume: 0.1,
			});


			this.interval = setInterval(() => {
				this.timerProgress -= decrement;
				this.setTimerProgress(this.timerProgress);
				if (this.timerProgress <= 0) {

					this.timeout();
				}

			}, 1000 / 30);
		}, timeout);

	}


	timeout(): void {
		this.soundPlayer.pause('assets/sounds/cobraz_quiz_time.mp3');
		clearInterval(this.interval);
		this.flashColor = "#c21c37";

		setTimeout(() => {

			this.index++;
			this.update();

		}, 1000);

	}
}
