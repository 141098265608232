import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { leaderboardAnimation } from 'src/app/animations/leaderboard.animations';
import { DataService } from 'src/app/services/data.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { Rank } from 'src/app/model/rank.interface';
import { ApiService } from 'src/app/services/api.service';
import { UserDataService } from 'src/app/services/user-data.service';

enum ScoreboardType
{
	Top,
	aroundScore,
};

@Component({
  selector: 'app-scoreboard',
  templateUrl: './score-board.component.html',
	styleUrls: ['./score-board.component.scss'],
})
export class ScoreboardComponent implements OnInit {
	
	private scores: Rank[];
	@Input() chapter: number;
	@Input() slots: number;
	@Input() currentScore: number;
	@Input() type: string;
	@Input() excludeFromTop: number;
	@Input() showHeader: number = 1;
	
  	constructor(
		private api: ApiService,
	  	private wordings: WordingsService,
		private modalService:ModalsService,
		private routerEvent:RouterEventService,
		public ds:DataService,
		public userdata: UserDataService,
	) { }

  	ngOnInit() {
		console.log(this.type);
		if(this.type === "aroundScore")
		{
			this.api.getLeaderboardAroundScore(this.ds.uid, this.currentScore, this.chapter, this.slots, this.excludeFromTop, this.userdata.playerscoreId)
			.subscribe(resp => {
				this.scores = resp.data.scores;
				this.scores.forEach(score => {
					if (score.nickname === "")
						score.nickname = this.wordings.translate("ui.rank.your_score")
				})
			});
		}
		else
		{
			this.api.getTopLeaderboard(this.ds.uid, this.currentScore, this.chapter, this.slots, this.userdata.playerscoreId)
			.subscribe(resp => {
				this.scores = resp.data.scores;
				console.log(resp.data.scores);
				
			});
		}
  	}
}
