import { Component, OnInit, AfterViewInit, HostBinding } from '@angular/core';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { environment } from 'src/environments/environment';
import { WordingsService } from 'src/app/services/wordings.service';
import { MesureImpactStateService } from "src/app/services/mesure-impact-state.service";
declare var dmi: any;
declare var pipwerks;

@Component({
	selector: 'app-score-send',
	templateUrl: './score-send.component.html',
	styleUrls: ['./score-send.component.scss'],
})
export class ScoreSendComponent implements OnInit, AfterViewInit {
	nickname: string;
	score: number;

	mesureImpactState: number = 0;
	skipped: boolean = false;
	choiceDisplayed: boolean = false;

	formChoiceContainer: HTMLElement;
	scoreSendContainer: HTMLElement;
	bonusPointsContainer: HTMLElement;

	constructor(
		private modalService: ModalsService,
		private api: ApiService,
		public ds: DataService,
		private wordings: WordingsService,
		private userData: UserDataService,
		private impactService: MesureImpactStateService,
	) { }

	ngOnInit() {
		// dmi.resetCookies();
		this.impactService.currentMessage.subscribe(message => {
			this.mesureImpactState = message;
			this.choiceDisplayed = this.ds.versionAllowedForm && this.mesureImpactState == 0 && !dmi.userIsFinished();
			if (dmi.userIsFinished()){
				this.userData.addMIScore(this.ds.dmiBonusPoints);
			}
			this.score = this.userData.getCurrentScoreWithoutMI();
	
			this.userData.save();
		});
	}

	ngAfterViewInit() {
		this.scoreSendContainer = document.getElementById("score_display");
		this.scoreSendContainer.style.display = "block";
	
	}

	valid(): void {
		this.score = this.userData.getCurrentScoreWithoutMI();
		//this.score = Math.round(this.score);

		let tab = location.href.match(new RegExp("uid=(.+)$"));
		this.ds.uid = tab && tab.length >= 1 && tab[1];

		this.ds.modeConnexion = (this.ds.uid == null || this.ds.uid == environment.defaultCompany) && environment.promptConnexion;

		if (this.ds.uid == null)
			this.ds.uid = environment.defaultCompany;


		if(pipwerks.SCORM.API.isFound)
		{
			let scoreMaxPercent = pipwerks.SCORM.get('cmi.core.score.max');
			// SCORM 2004 : cmi.core.lesson_status => cmi.completion_status
			pipwerks.SCORM.set('cmi.core.lesson_status', "completed");

			// SCORM 2004 : cmi.core.score_raw => cmi.score.raw
			let mainChaptersCount = this.ds.scenarios.length;
			let questionsCount = 0;

			for (let index = 1; index < this.ds.scenarios.length-1; index++) {
				let chapterQuiz = this.ds.scenarios[index];
				Object.values(chapterQuiz).forEach(question => {
					questionsCount++;
				});
			}

			let maxscoreInPoints = 11400; //mainChaptersCount * this.ds.params["maxScorePerChapter"] + questionsCount * this.ds.params["maxScorePerQuestion"];
			pipwerks.SCORM.set('cmi.core.score.raw', (this.score * parseInt(scoreMaxPercent) ) / maxscoreInPoints);
			pipwerks.SCORM.save();

			// SCORM 2004 : cmi.core.student_name => cmi.learner_name
			//this.nickname = pipwerks.SCORM.get('cmi.core.student_name');
		}
		this.api.saveScore(this.nickname, this.ds.uid, null, null)
			.subscribe(resp => {
				this.ds.leaderboard = resp.data.leaderboard;
				// #TODO : verifier que le playerscoreId est bien get et sauvegarde
				this.userData.playerscoreId = resp.data.scoreId;
				this.modalService.open('leaderboard');
			});
	}

	/*launchForm(): void {
		this.modalService.open("mesure-impact");
	}

	skipForm(): void {
		this.formChoiceContainer.style.display = "none";
		this.scoreSendContainer.style.display = "block";
	}*/
}
