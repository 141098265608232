import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

declare function getScoreData(uid);

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	
	defaultUID:string = environment.defaultCompany;
	

	constructor(
		private http:HttpClient,
		private ds: DataService,
	) { }
	
	
	private getRand():string
	{
		return ''+Math.round(Math.random() * 9999999);
	}
	
	
	public getChapter(index:number):Observable<any>
	{
		return this.http.get('assets/json/chapter-test.json');
		
	}
	
	getParams():Observable<any>
	{
		return this.http.get('assets/json/params.json');
	}
	
	getData(uid:string = null):Observable<any>
	{
		/*if(environment.mockApi){
			return this.http.get('assets/mock/getdata.mock.json');
		}*/
		if(!uid) uid = this.defaultUID;
		let body:any = {companyUid : uid};
		return this.http.post(environment.SERVICE_URL + '/getData?rnd='+this.getRand(), body);
	}

	registerUser(uid: string = this.defaultUID)
	{
		if(!uid) uid = this.defaultUID;
		let body:any = {companyUid : uid};
		return this.http.post(environment.SERVICE_URL + '/registerUser', body);
	}
	
	getPreloadList(uid:string = null):Observable<any>
	{
		if(!uid) uid = this.defaultUID;
		let body:any = {companyUid : uid};
		return this.http.post(environment.SERVICE_URL + '/getPreloadList', body);
	}
	
	getLeaderboard(uid:string):Observable<any>
	{
		if(!uid) uid = this.defaultUID;
		let headers:any = {};
		headers['Content-Type'] = 'application/x-www-form-urlencoded';
		
		let body:any = {
			companyUid: uid,
			limit: 10,
		};
		return this.http.post(environment.SERVICE_URL + '/getTopLeaderboard?rnd='+this.getRand(), body);
	}
	
	getLeaderboardAroundScore(uid:string, score:number, type:number, slots:number, excludeFromTop: number = -1, playerscoreId: number = -1) : Observable<any>
	{
		if(!uid) uid = this.defaultUID;
		let headers:any = {};
		headers['Content-Type'] = 'application/x-www-form-urlencoded';
		
		let body:any = {
			companyUid: uid,
			score: score,
			scoreType: type,
			limit: slots,
			excludeFromTop: excludeFromTop,
			playerscoreId: playerscoreId
		};
		return this.http.post(environment.SERVICE_URL + '/getLeaderboardAroundScore?rnd=' + this.getRand(), body);
	}

	getTopLeaderboard(uid:string, score:number, type:number, slots: number, playerscoreId: number = -1):Observable<any>
	{
		if(!uid) uid = this.defaultUID;
		let headers:any = {};
		headers['Content-Type'] = 'application/x-www-form-urlencoded';
		
		let body:any = {
			companyUid : uid,
			score : score,
			playerscoreId: playerscoreId,
			scoreType : type,
			limit: slots,
		};
		return this.http.post(environment.SERVICE_URL + '/getTopLeaderboard?rnd=' + this.getRand(), body);
	}
	
	saveScore(nickname:string, uid:string, additionalData1: string, additionalData2: string):Observable<any>
	{
		if(!uid) uid = this.defaultUID;
		console.log(uid);
		let data = getScoreData(uid);
		data.companyUid = uid;
		data.nickname = nickname;
		data.additional_data_1 = additionalData1;
		data.additional_data_2 = additionalData2;
		
		return this.http.post(environment.SERVICE_URL + '/saveScore', data);
	}

	login(login:string, password:string):Observable<any>
	{
		let body:any = {
			login,
			password,
		};
		return this.http.post(environment.SERVICE_URL + '/login', body);
	}
}
