import { WordingsService } from "../services/wordings.service";

export interface Company{
	
	uid:string,
	title:string,
	endBtnText:string,
	endBtnLink:string,
	endText:string,
	logoUrl:string,
};

export function setDefaultValues(company: Company, wordings: WordingsService)
{
	/*
	// remove company logoUrl default value, for new logos screens management
	if(company.logoUrl == '')
	{
		company.logoUrl = wordings.translate('ui.company.logo');
	}
	*/
}