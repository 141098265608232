import { Directive, Host, Self, Optional, ElementRef, Renderer2, HostListener, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { WindowRef } from '../utils/window.service';

@Directive({
  selector: '[scaledLayout]'
})
export class ScaledDirective {
	
	@Input('scaledLayout') options:scaledOptions;
	@Input('vnScaledMode') mode:string;
	@Input('vnScaledEnabled') enabled:boolean = true;
	@Input('vnScaledAnchor') anchor:scaledAnchorProp;
	
	
	
	
	@HostListener('window:resize', ['$event']) 
	onResize($event):void
	{
		this.handleResize($event.target);
	}

  constructor(
		@Host() @Self() @Optional() public host : ElementRef,
		public renderer: Renderer2,
		private windowRef:WindowRef,
	) {
	}
	
	
	
	handleResize(window):void
	{
		let enabled:boolean = this.getEnabled();
		if(!enabled) return;
		
		let windowWidth:number = window.innerWidth;
		let windowHeight:number = window.innerHeight;
		
		let el:any= this.host.nativeElement;
		/* 
		let elWidth:number = el.clientWidth;
		let elHeight:number = el.clientHeight;
		*/
		
		let scale:number;
		
		let mode:string = this.getMode();
		if(mode == 'contain'){
			scale = Math.min(
				windowWidth / this.options.uiWidth,
				windowHeight / this.options.uiHeight,
			);
			
			//console.log('scale : ');
			//console.log(windowWidth+' / '+this.options.uiWidth);
			//console.log(windowHeight+' / '+this.options.uiHeight);
			//console.log('= '+scale);
			
			
		}
		else if(mode == 'cover'){
			scale = Math.max(
				windowWidth / this.options.uiWidth,
				windowHeight / this.options.uiHeight,
			);
		}
		else throw new Error('ScaledDirective mode '+mode+' doesnt exist');
		
		
		let anchorstr:string = '';
		let anchor = this.getAnchor();
		if(anchor == scaledAnchorProp.CENTER) anchorstr = 'translate(-50%, -50%)'
		else if(anchor == scaledAnchorProp.TOP_LEFT) anchorstr = 'translate(0%, 0%)'
		else if(anchor == scaledAnchorProp.BOTTOM) anchorstr = 'translate(-50%, -100%)'
		
		
		let value:string = anchorstr + " " + "scale(" + scale + ")";
		this.renderer.setStyle(el, 'transform', value);
		// //console.log('ui : '+this.options.uiWidth+', '+this.options.uiHeight+' w : '+windowWidth+','+windowHeight+', scale : '+scale);
		
	}
	
	
	ngOnInit(): void {
		this.handleResize(this.windowRef.nativeWindow);
		
		this.enabled;
		
	}
	
	ngOnChanges(changes:SimpleChanges):void
	{
		if(changes.enabled){
			
			if(!this.enabled) this.reset();
			else this.handleResize(this.windowRef.nativeWindow);
			
		}
	}
	
	reset():void
	{
		let el:any= this.host.nativeElement;
		this.renderer.setStyle(el, 'transform', null);
	}
	
	
	getAnchor():scaledAnchorProp
	{
		if(this.anchor) return this.anchor;
		else if(this.options.anchor) this.options.anchor;
		return scaledAnchorProp.CENTER;
	}
	getMode():string
	{
		if(this.mode) return this.mode;
		else if(this.options.mode) this.options.mode;
		return 'contain';
	}
	getEnabled():boolean
	{
		// //console.log('get enabled '+this.enabled);
		return this.enabled;
	}
	
	

}




export interface scaledOptions{
	
	mode:string,
	uiWidth:number,
	uiHeight:number,
	anchor:scaledAnchorProp,
	
}


export enum scaledAnchorProp{
	TOP_LEFT = 'TOP_LEFT',
	TOP = 'TOP',
	TOP_RIGHT = 'TOP_RIGHT',
	CENTER_LEFT = 'CENTER_LEFT',
	CENTER = 'CENTER',
	CENTER_RIGHT = 'CENTER_RIGHT',
	BOTTOM_LEFT = 'BOTTOM_LEFT',
	BOTTOM = 'BOTTOM',
	BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}