import { Component, OnInit, HostBinding } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { tutoAnimation } from 'src/app/animations/tuto.animations';
import { DataService } from 'src/app/services/data.service';
import { RouterEventService } from 'src/app/shared/navigation/router-event.service';
import { WordingsService } from 'src/app/services/wordings.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { ModalsService } from 'src/app/shared/navigation/modals.service';
import { GameService } from 'src/app/services/game.service';

@Component({
  selector: 'app-tuto',
  templateUrl: './tuto.component.html',
  styleUrls: ['./tuto.component.scss'],
	animations: [tutoAnimation],
})
export class TutoComponent implements OnInit {
	
	// @HostBinding('@animations') private animations;
	index:number;
	isNext:boolean;
	label_valid5:string;

  constructor(
		private appNavigation:NavigationService,
		private ds:DataService,
		private wordings: WordingsService,
		private routerEvent:RouterEventService,
		private userData: UserDataService,
		private gameService: GameService,
		private modalService: ModalsService,
	) { }

  ngOnInit() {
		this.index = 0;
		this.isNext = true;
		
		if(!this.wordings.hasKey('ui.tuto3.textGauche')) this.wordings.setTranslation('ui.tuto3.textGauche', "zaoiru makjf masir azmlijr zm");
		if(!this.wordings.hasKey('ui.tuto3.textDroite')) this.wordings.setTranslation('ui.tuto3.textDroite', "zaoiru makjf masir azmlijr zm");
		
		this.label_valid5 = this.wordings.translate('ui.tuto5.launch');
		if(this.routerEvent.isRouteActive('tuto'))
		{
			this.label_valid5 = this.wordings.translate('ui.tuto5.menu.launch');
			this.gameService.hideBackgroundWithFade();
		}
	}
	
	
	valid():void
	{
		if(this.index < 4) this.index++;
		else if(this.routerEvent.isRouteActive('tuto'))
		{
			this.userData.incrChapter();
			this.modalService.open('chapter-start');
		}
		else this.modalService.open('menu', 'modal_menu');
	}
}
