import { Injectable } from '@angular/core';
import { UserData, createMock, applyDemoMode } from '../model/userdata.interface';
import { StorageService } from '../shared/storage/storage.service';
import { DataService } from './data.service';
import { ApiService } from './api.service';
import { ActivatedRoute } from '@angular/router';

declare function encryptProgression(data: any, uid: string);
declare function decryptProgression(uid: string);


@Injectable({
  providedIn: 'root'
})
export class UserDataService {

	public data:UserData;
	private isDemoMode = false;
	public playerscoreId: number = -1;
	
	constructor(
		private storage:StorageService,
		private api: ApiService,
		private ds: DataService,
		private route: ActivatedRoute,
	) { }
	
	
	public init(): void
	{
		if (localStorage.getItem("selectedLanguage") == null) {
			localStorage.setItem("selectedLanguage", "fr_FR");
			window.location.reload();
		}
		let tab = location.href.match(new RegExp("uid=(.+)$"));
		let uid = tab && tab.length  >= 1 && tab[1];
		if(uid == null)
			uid = this.api.defaultUID;
		this.data = decryptProgression(uid);
		if(!this.data){
			this.data = createMock();
			this.route.queryParams
				.subscribe({
					next: (params) => {
						console.log(params);
						if(params.test != undefined)
						{
							applyDemoMode(this.data);
							this.save();
						}	
					}
				}
			);
			
			if(!this.data.indexScenario) this.data.indexScenario = 0;
			if(!this.data.scores)
				this.data.scores = [];
		}

		if(uid == "test-version-com"){
			this.data.indexScenario = 3;
			this.data.nodeId = null;
			this.data.scores = [0, 1000, 1000, 1000, 1000];
			this.data.quizScores = [0, 1000, 1000, 1000, 1000];
			this.data.dataActions = {
				jauge1:50
			};
			this.data.ngGameover = 0;
		}
	}
	
	public addLog(talker:string, msg:string, isDidascalie:boolean = false):void
	{
		let item:any;
		let logs = this.data.logs;
		
		let exists:boolean = !logs.every(item => item.talker != talker || item.msg != msg);
		if(!exists) this.data.logs.push({
			talker, 
			msg, 
			isDidascalie
		});
	}
	
	public incrChapter()
	{
		this.data.indexScenario++;
		this.data.nodeId = null;
		this.save();
	}

	public applyChapterScore()
	{
		if(this.data.indexScenario > 0)
		{
			for(let i=this.data.scores.length; i<this.data.indexScenario - 1; i++)
			{
				this.data.scores.push(0);
			}
			this.data.scores[this.data.indexScenario - 1] = this.getCurrentChapterScore();
		}
		for(let i=this.data.quizScores.length; i<this.ds.scenarios.length - 2; i++)
		{
			this.data.quizScores.push(0);
		}
	}

	public addQuizScore(score: number)
	{
		for(let i=this.data.quizScores.length; i<this.ds.scenarios.length; i++)
		{
			this.data.quizScores.push(0);
		}
		for(let i=this.data.scores.length; i<this.ds.scenarios.length; i++)
		{
			this.data.scores.push(0);
		}
		this.data.quizScores[this.data.indexScenario - 1] = score;
	}

	public addMIScore(score: number)
	{
		
		this.data.miScore = score;
	}

	public getCurrentChapterScore(): number
	{
		return this.data.dataActions['jauge1'] * this.getPointPerPercent();
	}

	public getPointPerPercent(): number
	{
		return 30;
	}

	public getChapterTotalScore(lastFinishedChapter: number): number
	{
		let result = 0;
		for(let i = 0; i < lastFinishedChapter; i++)
		{
			result += this.data.scores[i];
			result += this.data.quizScores[i];
		}
		return result;
	}

	public getCurrentTotalScore(): number
	{
		let result = 0;
		for(let i = 0; i < this.data.scores.length; i++)
		{
			result += this.data.scores[i];
		}
		for(let i = 0; i < this.data.quizScores.length; i++)
		{
			result += this.data.quizScores[i];
		}
		result += this.data.miScore;
		return result;
	}

	public getCurrentScoreWithoutMI(): number
	{
		let result = 0;
		for(let i = 0; i < this.data.scores.length; i++)
		{
			result += this.data.scores[i];
		}
		for(let i = 0; i < this.data.quizScores.length; i++)
		{
			result += this.data.quizScores[i];
		}
		return result;
	}
	
	public resetChapter():void
	{
		this.data.dataActions = Object.assign({}, this.data.chapterStartData);
		//console.log(this.data.dataActions);
		this.data.logs = [];
		this.data.nodeId = null;
	}
	
	public resetAll():void
	{
		this.data.chapterStartData = {};
		this.resetChapter();
		this.data.bg = null;
		this.data.indexScenario = 0;
		this.data.scores = [];
		this.data.quizScores = [];
		this.playerscoreId = -1;

		if(this.ds.uid == "test-version-com"){
			this.data.indexScenario = 3;
			this.data.nodeId = null;
			this.data.scores = [0, 1000, 1000, 1000, 1000];
			this.data.quizScores = [0, 1000, 1000, 1000, 1000];
			this.data.dataActions = {
				jauge1:50
			};
			this.data.ngGameover = 0;
		}
	}
	
	public save():void
	{
		let uid = this.ds.uid;
		if(uid == null)
			uid = this.api.defaultUID;
		encryptProgression(this.data, uid);
	}

	public loadCheckpoint(): void
	{
		let uid = this.ds.uid;
		if(uid == null)
			uid = this.api.defaultUID;
		this.data = this.storage.getObject('checkpoint_user_' + uid);
		if(!this.data){
			this.data = createMock();
			this.save();
		}
		if(!this.data.indexScenario) this.data.indexScenario = 0;
	}

	public setCheckpoint(): void
	{
		let uid = this.ds.uid;
		if(uid == null)
			uid = this.api.defaultUID;
		this.storage.setObject('checkpoint_user_' + uid, this.data);
	}
}
